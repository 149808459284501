import Vue from 'vue'

export default {
  async getUniversitySuggestions({ query, count, userId }) {
    return (await Vue.axios.get(`dashboard/universities/${query}/${count}/${userId}`)).data
  },
  getAddressSuggestions(query, count) {
    return Vue.axios.get(`dashboard/geocode/${query}/${count}`)
  }
}
