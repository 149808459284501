<template>
  <div :class="$style.newParent">
    <Title text="Создание родителя" position="right" />
    <h3>ФИО</h3>
    <div :class="$style.newParent__row">
      <Input
        v-model="fields.attributes.firstName"
        :class="$style.newParent__input"
        placeholder="Имя*"
        :error="errors.firstName && !firstFill"
        @input="setText('firstName')"
      />
      <Input
        v-model="fields.attributes.lastName"
        :class="$style.newParent__input"
        placeholder="Фамилия*"
        :error="errors.lastName && !firstFill"
        @input="setText('lastName')"
      />
      <Input
        v-model="fields.attributes.middleName"
        :class="$style.newParent__input"
        placeholder="Отчество"
      />
    </div>

    <div :class="$style.newParent__row">
      <div>
        <h3>Телефон</h3>
        <vue-tel-input
          v-model="fields.phone"
          mode="international"
          style="height: 34px;"
          :class="[$style.newParent__input, {[$style.error]: errors.phone}]"
          :input-options="{
            maxlenght: 25,
            autocomplete: 'on',
            showDialCode: true,
            type: 'tel',
            id: '',
            name: 'telephone',
            tabindex: '0',
            'aria-descibedby': ''
          }"
          @input="(v) => setPhone(v)"
          @validate="(v) => validatePhone(v)"
        ></vue-tel-input>
        <!-- <Input
          v-model="fields.phone"
          :class="$style.newParent__input"
          :mask="'7(###) ###-##-##'"
          placeholder="7(___) ___-__-__*"
          :error="errors.phone && !firstFill"
          @input="setPhone"
        /> -->
      </div>
      <div>
        <h3>PIN-код</h3>
        <Input
          v-model="fields.pinCode"
          :class="$style.newParent__input"
          :mask="'####'"
          placeholder="____*"
          :error="errors.pinCode && !firstFill"
          @input="setPinCode"
        />
      </div>
    </div>

    <h3>Почта</h3>
    <Input
      v-model="fields.email"
      :class="$style.newParent__input"
      placeholder="________@____.__*"
      :error="errors.email && !firstFill"
      @input="setEmail"
    />

    <h3>Город</h3>
    <div :class="$style.newParent__wrapper">
      <Input
        v-model="locality.region"
        :class="$style.newParent__address"
        type="secondary"
        placeholder="Выберите из списка*"
        :autocomplete="false"
        :error="errors.region && !firstFill"
        @input="getRegions(locality.region)"
        @enter="setRegion(locality.region)"
      />

      <transition name="select">
        <div v-if="regions.length > 0" :class="$style.newParent__dropdown">
          <p
            v-for="(r, i) in regions"
            :key="i"
            :class="$style.newParent__region"
            @click="setRegion(r.region)"
          >
            {{ r.region }}
          </p>
        </div>
      </transition>

      <div v-if="regions.length > 0" :class="$style.newParent__close" @click="resetRegions" />
    </div>

    <!-- <h3>Дата рождения</h3>
    <date-picker
      v-model="fields.attributes.birthday"
      :class="$style.newParent__datepicker"
      :lang="lang"
      placeholder="Дата"
    /> -->

    <div :class="$style.newParent__buttons">
      <Button :class="$style.newParent__buttons_button" :type="'primary'" @click="create">
        Создать нового родителя
      </Button>
    </div>
  </div>
</template>

<script>
import Title from '../basic/Title'
import Input from '../basic/Input'
import Button from '../basic/Button'

// import DatePicker from 'vue2-datepicker'
import ru from 'vue2-datepicker/locale/ru'
import { VueTelInput } from 'vue-tel-input'
import Moment from 'moment'
import 'vue2-datepicker/index.css'
import userApi from '../api/users'
import dadataApi from '../api/dadata'

export default {
  name: 'NewParent',
  components: {
    Title,
    Button,
    Input,
    VueTelInput
    // DatePicker
  },
  data() {
    return {
      lang: ru,
      fields: {
        attributes: {
          firstName: null,
          lastName: null,
          middleName: null
          // birthday: null,
          // timezone: 3
        },
        phone: null,
        pinCode: null,
        email: null
      },
      momentFormat: {
        stringify: (date) => {
          return date ? Moment(date).format('DD.MM.YYYY') : ''
        }
      },
      errors: {
        firstName: true,
        lastName: true,
        phone: true,
        pinCode: true,
        email: true,
        region: true
      },
      firstFill: true,
      regions: [],
      locality: {
        region: '',
        latitude: null,
        longitude: null,
        timezone: null
      }
    }
  },
  methods: {
    setText(text) {
      if (this.fields.attributes[text]?.length >= 2) {
        this.errors[text] = false
      } else {
        this.errors[text] = true
      }
    },
    setPhone(phone) {
      this.fields.phone = phone
    },
    setPinCode() {
      if (this.fields.pinCode?.length === 4) {
        this.errors.pinCode = false
      } else {
        this.errors.pinCode = true
      }
    },
    setEmail() {
      if (/.+@.+\.[A-Za-z]+$/.test(this.fields.email)) {
        this.errors.email = false
      } else {
        this.errors.email = true
      }
    },
    validate() {
      return (
        this.fields.attributes.firstName &&
        this.fields.attributes.lastName &&
        this.fields.pinCode?.length === 4 &&
        /.+@.+\.[A-Za-z]+$/.test(this.fields.email) &&
        !this.errors.region
      )
    },
    validatePhone(phoneObject) {
      this.errors.phone = !phoneObject.valid
    },
    async getRegions(query) {
      this.errors.region = true

      if (query.length >= 2 && query.length % 3 === 0) {
        try {
          const { data } = await dadataApi.getAddressSuggestions(query, 5)

          if (data?.success) {
            this.regions = data.result
          }
        } catch (error) {
          console.warn(error)
          await this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Ошибка',
              message: 'Ошибка получения предложения адресов с сервера!'
            }
          ])
        }
      } else {
        this.regions = []
      }
    },
    resetRegions() {
      this.regions = []
    },
    async setRegion(region) {
      this.regions = []

      try {
        const { data } = await dadataApi.getAddressSuggestions(region, 1)

        if (data?.success && data?.result[0]?.timezone) {
          this.locality = {
            region,
            latitude: data.result[0].latitude ?? null,
            longitude: data.result[0].longitude ?? null,
            timezone: +/\d+/.exec(data.result[0].timezone)
          }
          this.errors.region = false
        } else {
          await this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Ошибка',
              message:
                'По данному населённому пункту нет дополнительной информации, пожалуйста, выберите ближайший крупный город.'
            }
          ])
        }
      } catch (error) {
        console.warn(error)
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка получения информации по адресу с сервера!'
          }
        ])
      }
    },
    async create() {
      if (!this.validate()) {
        await this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Ошибка!', message: 'Заполните все обязательные поля!' }
        ])
        this.firstFill = false
        return
      }

      try {
        const { data } = await userApi.checkUserPhoneOrEmail({
          phone: this.fields.phone.replace(/\D/g, ''),
          email: this.fields.email
        })
        const phone = data?.result?.phone === false
        const email = data?.result?.email === false

        if (phone || email) {
          await this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Ошибка!',
              message: `Пользователь с ${!phone && email ? 'данной' : 'данным'} ${
                phone ? 'телефоном' : ''
              }${phone && email ? ' и ' : ''}${email ? 'почтой' : ''} уже существует!`
            }
          ])
          return
        }

        let { success, result } = await userApi.createParent({
          fields: {
            attributes: {
              ...this.fields.attributes,
              ...this.locality
            },
            phone: this.fields.phone.replace(/\D/g, ''),
            pinCode: this.fields.pinCode,
            email: this.fields.email
          }
        })
        if (success) {
          await this.$store.dispatch('openModal', [
            'Alert',
            { title: 'Успех', message: 'Пользователь был успешно создан!' }
          ])
          await this.$router.push(`/parents/${result.id}`)
        }
      } catch (e) {
        console.warn(e)
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка получения данных с сервера!'
          }
        ])
      }
    }
  }
}
</script>
<style src="vue-tel-input/dist/vue-tel-input.css"></style>
<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.error {
  border: 1px solid red;
}
.newParent {
  h2 {
  }
  &__row {
    display: flex;
  }
  &__input {
    width: 16rem;
    margin: 0.5rem 1rem 1.5rem 0;
    margin-left: 0 !important;
  }
  &__address {
    width: 100%;
    margin: 0.5rem 1rem 1.5rem 0;
    margin-left: 0 !important;
  }
  &__wrapper {
    position: relative;
    width: 25rem;
  }
  &__dropdown {
    overflow: hidden;
    position: absolute;
    z-index: 99;
    left: 0;
    right: 0;
    top: 2.3rem;
    background: $white;
    border: 1px solid $platinum;
    color: $wet-asphalt;
  }
  &__region {
    padding: 0.5rem 0.625rem;
    border-bottom: 1px solid $gainsborough;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $green-tea;
    }

    &:last-child {
      border-bottom: none;
    }
  }
  &__close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 80;
  }
  // &__datepicker {
  //   width: 16rem !important;
  //   margin: 0.5rem 1rem 1.5rem 0;
  //   input {
  //     height: 34px;
  //     padding: 0.5rem 0.75rem;
  //     border: 1px solid #f0f0f0;
  //     border-radius: 0.25rem;
  //     box-shadow: none;
  //     font-size: 1rem;
  //   }
  // }
  &__buttons {
    width: 100%;
    display: flex;
    &_button {
      width: auto !important;
      font-size: 1rem !important;
      text-align: center !important;
      margin: 0 !important;
    }
  }
}
</style>
